body {
  margin: 0;
  margin-top: 70px;
  margin-bottom: 70px;
   
  background-color: rgb(162, 162, 162);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
H1{color: rgb(12, 12, 63);}
H2{color: rgb(25, 25, 78);}
H3{color: rgb(17, 17, 105);}
H4{color: rgb(10, 46, 94);}
H5{color: rgb(23, 90, 129);}
H6{color: rgb(23, 6, 63);}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
